import Tabs from '@/components/Tabs'
import {
  MOBILE_HEADER_DEFAULT_HEIGHT,
  PC_HEADER_DEFAULT_HEIGHT,
} from '@/constants/Layout'
import elementItemHelper from '@/helpers/elementItemHelper'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ContentRow, ContentTabItem } from '@/types/Content'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

interface Grid1FullTabProps {
  item: ContentRow
  activeTabId?: number
  onTabChange?: (tab: ContentTabItem) => void
}

interface HashScrollProps {
  htmlId?: string
  isScrollToHash?: boolean
}

function Grid1FullTab({ item, onTabChange, activeTabId }: Grid1FullTabProps) {
  const { events } = useRouter()
  const topRef = useRef<HTMLDivElement | null>(null)
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const tabRef = useRef<HTMLDivElement | null>(null)
  const prevPath = useRef<string>('')

  const { columns, htmlId, isScrollToHash } = item
  const [{ items }] = columns ?? [{}]
  const { tab } = elementItemHelper(items)

  const hashScrollProps = Object.assign(
    { isScrollToHash },
    isScrollToHash && { 'data-is-scroll-to-hash': true }
  )

  const handleClickTab = async (tabId: number) => {
    const targetTab = tab.find((tabItem) => tabItem.id === tabId)
    await onTabChange?.(targetTab)
  }

  useEffect(() => {
    prevPath.current = window.location.pathname
    const scrollToNavTop = (path: string) => {
      if (prevPath.current !== path) {
        topRef.current?.scrollIntoView()
      }
      prevPath.current = window.location.pathname
    }
    events.on('routeChangeComplete', scrollToNavTop)

    return () => {
      events.off('routeChangeComplete', scrollToNavTop)
    }
  }, [events])

  return (
    <>
      <Styled.TopRef
        ref={topRef}
        id={htmlId}
        key={Date.now()}
        {...hashScrollProps}
      />
      <Styled.Sticky ref={tabRef}>
        <Styled.Tabs
          list={tab}
          onClick={handleClickTab}
          activeTabId={activeTabId}
          hiddenTitle={item?.tabHiddenTitle}
          padding
        />
      </Styled.Sticky>
      <Styled.BottomRef ref={bottomRef} />
    </>
  )
}

const Styled = {
  Sticky: styled.div`
    position: relative;
    position: sticky;
    z-index: 10;
    background-color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      top: ${MOBILE_HEADER_DEFAULT_HEIGHT}px;
    }
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      top: ${PC_HEADER_DEFAULT_HEIGHT}px;
      border-top: 1px solid ${STYLE_COLOR.BLACK04};
      border-bottom: 1px solid ${STYLE_COLOR.BLACK05};
    }
  `,
  Tabs: styled(Tabs)`
    padding-top: 30px;
    padding-bottom: 43px;
    text-align: center;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-bottom: 0;
      padding-top: 0;
    }
  `,
  TopRef: styled.u<HashScrollProps>`
    position: relative;
    z-index: -1;
    display: block;
    overflow: hidden;
    max-height: 0;
    margin-bottom: -1px;
    visibility: hidden;

    ${({ isScrollToHash }) => {
      if (isScrollToHash) {
        return css`
          padding-top: ${PC_HEADER_DEFAULT_HEIGHT}px;
          margin-top: -${PC_HEADER_DEFAULT_HEIGHT}px;

          @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
            padding-top: ${MOBILE_HEADER_DEFAULT_HEIGHT}px;
            margin-top: -${MOBILE_HEADER_DEFAULT_HEIGHT}px;
          }
        `
      }
    }}
  `,
  BottomRef: styled.u`
    position: relative;
    display: block;
    overflow: hidden;
    height: 0;
    max-height: 0;
  `,
}

export default Grid1FullTab
